import axios from "axios"

export default {
    state: {
        cases: [],
        casesFetched: false,
        openCase: null,
        openCaseCalls: [],
    },
    mutations: {
        getCases(state, cases) {
            state.cases = cases;
        },
        setCasesFetched(state, casesFetched) {
            state.casesFetched = casesFetched;
        },
        setOpenCase(state, shipCase) {
            state.openCase = shipCase;
        },
        setOpenCaseCalls(state, openCaseCalls) {
            state.openCaseCalls = openCaseCalls;
        },
    },
    actions: {
        async getCases({ commit, state }, refresh = false) {
            if (state.casesFetched && !refresh) return;

            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/shipOwner/cases`);

                commit("getCases", res.data);
                commit("setCasesFetched", true);

            } catch (e) {
                throw e;
            }
        },
        async getOpenCaseCalls({ state, commit }) {
            try {

                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/shipowner/casecalls/${state.openCase.shipCaseId}`);

                commit("setOpenCaseCalls", res.data);

            } catch (e) {
                throw e;
            }
        },
    },
}
