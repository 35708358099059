<template>
  <div class="component-wrapper d-flex flex-column justify-start align-center">
    <Cases />
  </div>
</template>

<script>
import Cases from "@/views/Cases";

export default {
  components: {
    Cases,
  },
};
</script>
