<template>
  <div>
    <div
      class="toolbar-wrapper py-2 px-5 d-flex justify-center align-center elevation-2"
    >
      <v-btn
        icon
        color="primary"
        large
        class="menu-btn"
        v-if="isLoggedIn"
        @click="drawer = true"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <img src="@/assets/logo.svg" />
    </div>

    <v-navigation-drawer v-model="drawer" app temporary>
      <Sidebar />
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Sidebar from "@/components/Sidebar";

export default {
  components: {
    Sidebar,
  },
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
};
</script>

<style lang="scss" scoped>
.toolbar-wrapper {
  height: 64px;
  position: relative;

  img {
    height: 100%;
  }

  .menu-btn {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
  }
}
</style>