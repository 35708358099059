<template>
  <div class="d-flex flex-column pa-2">
    <v-btn text color="primary" @click="onLogout" :loading="loader"
      >Log out</v-btn
    >
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      loader: false,
    };
  },
  methods: {
    ...mapActions(["logout"]),
    async onLogout() {
      this.loader = true;

      await this.logout();

      this.loader = false;
      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>