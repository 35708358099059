import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth';
import base from './base';
import shipcare from './shipcare';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    base,
    auth,
    shipcare
  }
})
