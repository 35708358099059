<template>
  <div class="component-wrapper d-flex flex-column justify-start align-center">
    <form
      class="form elevation-3 d-flex flex-column pa-8 pt-5 rounded"
      @submit.prevent="submit"
      novalidate
    >
      <div class="text-h6 text-center mb-4">Ship Owner Login</div>

      <v-text-field
        outlined
        label="Email"
        type="email"
        prepend-inner-icon="mdi-account"
        v-model="email"
        :error="wrongCredentials"
      ></v-text-field>

      <v-text-field
        outlined
        label="Password"
        type="password"
        prepend-inner-icon="mdi-lock"
        v-model="password"
        :error-messages="
          wrongCredentials ? 'Incorrect username or password.' : ''
        "
      ></v-text-field>

      <v-btn
        color="primary"
        type="submit"
        :loading="loader"
        :disabled="!email || !password"
        >Login</v-btn
      >
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      email: "",
      password: "",
      loader: false,
      wrongCredentials: false,
    };
  },
  methods: {
    ...mapActions(["login"]),
    async submit() {
      this.wrongCredentials = false;
      this.loader = true;

      try {
        await this.login({
          username: this.email,
          password: this.password,
        });

        this.loader = false;
        this.$router.push({ path: "/" });
      } catch (e) {
        this.loader = false;
        this.wrongCredentials = true;
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  width: 450px;
  margin-top: 50px;
}
</style>