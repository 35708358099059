<template>
  <v-app>
    <Navigation />

    <v-main>
      <router-view></router-view>
    </v-main>

    <Footer />
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer";
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import axios from "axios";

export default {
  components: {
    Navigation,
    Footer,
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
    ...mapState({
      token: (state) => state.auth.token,
    }),
  },
  async created() {
    //====== LOGIN STUFF ======
    if (this.isLoggedIn) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${this.token}`;
    }

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          this.logout().then(() => {
            this.$router.push({ path: "/login" });
          });
        }
        throw error;
      }
    );
  },
  methods: {
    ...mapActions(["logout"]),
  },
};
</script>


<style lang="scss" >
//====== VUETIFY OVERRIDES ======

.v-main__wrap {
  display: flex;
}

.v-pagination__navigation {
  outline: none;
}

.v-pagination__item {
  outline: none;
}

.v-data-table__selected {
  .v-data-table__checkbox {
    .v-icon {
      color: var(--v-primary-base);
    }
  }
}

.cases-checkbox {
  .v-input--selection-controls__input {
    background-color: white;
    height: 17px !important;
    width: 17px !important;
  }
}

.v-btn.v-size--x-small {
  .v-progress-circular {
    height: 15px !important;
    width: 15px !important;
  }
}

//====== GLOBAL STYLES ======
.component-wrapper {
  flex-grow: 1;
  padding: 20px;
}
</style>