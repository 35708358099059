<template>
  <div class="elevation-2 rounded-b pb-2 data-card d-flex flex-column">
    <div class="primary text-center pa-1 text-h6 white--text rounded-t">
      Latest Cases
    </div>

    <div class="d-flex align-center my-2 px-4">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        class="flex-grow-1 mr-2"
        outlined
        dense
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </div>

    <v-data-table
      :headers="headers"
      :items="casesMapped"
      :items-per-page="itemsPerPage"
      :search="search"
      hide-default-footer
      @page-count="pageCount = $event"
      :page.sync="page"
      :sort-by.sync="sortby"
      :custom-sort="customSort"
      :sort-desc.sync="sortDesc"
      class="rounded-0 rounded-b mb-2"
      :loading="loader"
    >
      <template #item="{ item }">
        <tr>
          <td>{{ item.seafarerName }}</td>

          <td>{{ item.seafarerSBN }}</td>

          <td>{{ item.shipName }}</td>

          <td>{{ item.startMasterName }}</td>

          <td>{{ item.start.format("DD/MM/YYYY") }}</td>

          <td>
            <v-btn
              small
              color="primary"
              @click="onOpenCallsDialog(item)"
              width="95px"
              >{{ item.totalCallCount }} call{{
                item.totalCallCount == 1 ? "" : "s"
              }}</v-btn
            >
          </td>

          <td>{{ item.lastAlertDate.format("DD/MM/YYYY") }}</td>

          <td>{{ item.IsClosed ? "Yes" : "No" }}</td>
        </tr>
      </template>
    </v-data-table>

    <v-pagination
      class="mt-auto"
      v-model="page"
      :length="pageCount"
    ></v-pagination>

    <v-dialog v-model="callsDialog" class="align-self-center" max-width="800">
      <Calls v-if="callsDialog" @closeCallsDialog="callsDialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import Calls from "@/views/Calls";
import moment from "moment";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  components: {
    Calls,
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      search: "",
      sortby: "",
      sortDesc: true,
      dialog: false,
      headers: [
        { text: "Seafarer", value: "seafarerName" },
        { text: "Seafarer SBN", value: "seafarerSBN" },
        { text: "Ship", value: "shipName" },
        { text: "Start Master", value: "startMasterName" },
        { text: "Date Started", value: "seafarerName" },
        { text: "Number of Calls", value: "totalCallCount" },
        { text: "Last Call Date", value: "lastAlertDate" },
        { text: "Finished", value: "totalCallCount" },
      ],
      callsDialog: false,
      loader: false,
    };
  },
  computed: {
    ...mapState({
      cases: (state) => state.shipcare.cases,
    }),
    casesMapped() {
      return this.cases.map((c) => {
        return {
          ...c,
          start: moment(c.start),
          lastAlertDate: moment(c.lastAlertDate),
        };
      });
    },
  },
  async created() {
    this.loader = true;

    await this.getCases();

    this.loader = false;
  },
  methods: {
    ...mapMutations(["setOpenCase", "setOpenCaseCalls"]),
    ...mapActions(["getCases"]),
    customSort(items, sortBy, sortDesc) {
      sortBy = sortBy[0];
      sortDesc = sortDesc[0];

      items.sort((a, b) => {
        if (sortBy === "lastAlertDate") {
          if (!sortDesc) {
            const dateA = a[sortBy];
            const dateB = b[sortBy];
            return dateA.diff(dateB);
          } else {
            const dateA = b[sortBy];
            const dateB = a[sortBy];
            return dateA.diff(dateB);
          }
        } else {
          if (!sortDesc) {
            return a[sortBy] < b[sortBy] ? -1 : 1;
          } else {
            return b[sortBy] < a[sortBy] ? -1 : 1;
          }
        }
      });

      return items;
    },
    onOpenCallsDialog(shipCase) {
      this.setOpenCase(shipCase);
      this.callsDialog = true;
    },
  },
  watch: {
    callsDialog(newValue) {
      if (!newValue) {
        this.setOpenCase(null);
        this.setOpenCaseCalls([]);
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.data-card {
  width: 1000px;
  height: 684px;
}
</style>