<template>
  <div class="component-wrapper d-flex flex-column white pb-2">
    <v-btn class="mb-4" icon small @click="$emit('closeCallsDialog')">
      <v-icon> mdi-close </v-icon>
    </v-btn>

    <div class="primary text-center pa-1 text-h6 white--text rounded-t">
      Case
    </div>

    <v-data-table
      :headers="caseHeaders"
      :items="openCaseMapped"
      :items-per-page="5"
      hide-default-footer
      class="rounded-0 rounded-b mb-5"
    >
    </v-data-table>

    <div class="primary text-center pa-1 text-h6 white--text rounded-t">
      Case Videocalls
    </div>

    <v-data-table
      :headers="headers"
      :items="openCaseCallsMapped"
      :items-per-page="5"
      hide-default-footer
      class="rounded-0 rounded-b mb-2"
      :loading="loader"
      @page-count="pageCount = $event"
      :page.sync="page"
      :sort-by.sync="sortby"
      :custom-sort="customSort"
      :sort-desc.sync="sortDesc"
    >
      <template #item="{ item }">
        <tr>
          <td>{{ item.startDate.format("DD/MM/YYYY") }}</td>
          <td>{{ item.durationSec }}</td>
          <td>{{ item.doctorFirstName ? item.doctorFirstName : "-" }}</td>
          <td>{{ item.doctorLastName ? item.doctorLastName : "-" }}</td>
          <td>{{ item.doctorTypeName ? item.doctorTypeName : "-" }}</td>
          <td>
            <a :href="item.medicalReportLink" class="medical-report-link">{{
              item.medicalReportLink
            }}</a>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-pagination
      class="mt-auto"
      v-model="page"
      :length="pageCount"
    ></v-pagination>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      loader: false,
      caseHeaders: [
        { text: "Seafarer", value: "seafarerName", sortable: false },
        { text: "Seafarer SBN", value: "seafarerSBN", sortable: false },
        { text: "Ship", value: "shipName", sortable: false },
        { text: "Start Master", value: "startMasterName", sortable: false },
        { text: "Date Started", value: "seafarerName", sortable: false },
        { text: "Number of Calls", value: "totalCallCount", sortable: false },
        { text: "Last Call Date", value: "lastAlertDate", sortable: false },
        { text: "Finished", value: "totalCallCount", sortable: false },
      ],
      headers: [
        { text: "Date", value: "startDate" },
        { text: "Duration", value: "durationSec", sortable: false },
        {
          text: "Doctor First Name",
          value: "doctorFirstName",
          sortable: false,
        },
        { text: "Doctor Last Name", value: "doctorLastName", sortable: false },
        { text: "Doctor Specialty", value: "doctorTypeName", sortable: false },
        {
          text: "Medical Report Link",
          value: "medicalReportLink",
          sortable: false,
        },
      ],
      page: 1,
      pageCount: 0,
      sortby: "",
      sortDesc: true,
    };
  },
  computed: {
    ...mapState({
      openCase: (state) => state.shipcare.openCase,
      openCaseCalls: (state) => state.shipcare.openCaseCalls,
    }),
    openCaseMapped() {
      let openCase = { ...this.openCase };
      openCase.start = moment(openCase.start).format("DD/MM/YYYY");

      openCase.lastAlertDate = moment(openCase.lastAlertDate).format(
        "DD/MM/YYYY"
      );

      const shipCase = [];
      shipCase.push(openCase);

      return shipCase;
    },
    openCaseCallsMapped() {
      return this.openCaseCalls.map((c) => {
        return {
          ...c,
          startDate: moment(c.startDate),
        };
      });
    },
  },
  async created() {
    this.loader = true;

    try {
      await this.getOpenCaseCalls();
      this.loader = false;
    } catch (e) {
      console.log(e);
      this.loader = false;
    }
  },
  methods: {
    ...mapActions(["getOpenCaseCalls"]),
    customSort(items, sortBy, sortDesc) {
      sortBy = sortBy[0];
      sortDesc = sortDesc[0];

      items.sort((a, b) => {
        if (sortBy === "startDate") {
          if (!sortDesc) {
            const dateA = a[sortBy];
            const dateB = b[sortBy];
            return dateA.diff(dateB);
          } else {
            const dateA = b[sortBy];
            const dateB = a[sortBy];
            return dateA.diff(dateB);
          }
        } else {
          if (!sortDesc) {
            return a[sortBy] < b[sortBy] ? -1 : 1;
          } else {
            return b[sortBy] < a[sortBy] ? -1 : 1;
          }
        }
      });

      return items;
    },
  },
};
</script>

<style lang="scss" scoped>
.component-wrapper {
  height: 608px;
  overflow: auto;
}

.medical-report-link {
  display: inline-block;
  width: 125px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
</style>