<template>
  <div class="footer-wrapper d-flex flex-column align-center py-2 px-5">
    <div class="body-2 primary--text mb-1">&copy; {{ year }} DN2ME</div>
    <!-- <div class="body-2 primary--text">More footer stuff here...</div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss" scoped>
.toolbar-wrapper {
  height: 100px;

  img {
    height: 100%;
  }
}
</style>