import axios from 'axios'

export default {
    state: {
        token: localStorage.getItem('token') || null
    },
    getters: {
        isLoggedIn: state => !!state.token
    },
    mutations: {
        login(state, token) {
            state.token = token;
        },
        logout(state) {
            state.token = null;
        }
    },
    actions: {
        async login({ commit, dispatch }, credentials) {
            try {
                const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/user/login`, {
                    username: credentials.username,
                    password: credentials.password,
                    application: "app_shipowner",
                });

                const token = res.data.token;
                localStorage.setItem('token', token);
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                commit('login', token)
            } catch (e) {
                dispatch("logout");
                throw e;
            }
        },
        async logout({ commit }) {
            commit('logout');
            localStorage.removeItem('token');
            delete axios.defaults.headers.common['Authorization'];
        }
    },
}
